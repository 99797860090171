import { useState } from "react";
import { StyledContactForm } from "./ContactForm.styled";
import { ContactFormInput } from "../../../Models/ContactFormInput";
import { Box, Button, TextField } from "@mui/material";
import { useEmail } from "../../../Hooks/useEmail";
import { Message } from "../../Shared/Message/Message";
import { useForm } from "react-hook-form";

export function ContactForm() {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormInput>();
  const { sendEmail } = useEmail();

  const onSubmit = async (data: ContactFormInput) => {
    const status = await sendEmail(data);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Je email is verzonden!");
    }
    setShowMessage(true);
  };

  return (
    <StyledContactForm>
      <Message
        status={responseStatus}
        showMessage={showMessage}
        onFinish={() => {
          setShowMessage(false);
          setMessage("");
        }}
      >
        {message}
      </Message>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="input-small">
          <TextField
            label="Voornaam"
            variant="standard"
            {...register("name", { required: "Dit veld is verplicht" })}
            error={!!errors.name}
            helperText={errors?.name?.message}
          />
          <TextField
            label="Achternaam"
            variant="standard"
            {...register("surname", { required: "Dit veld is verplicht" })}
            error={!!errors.surname}
            helperText={errors?.surname?.message}
          />
        </Box>
        <TextField
          label="E-mailadres"
          variant="standard"
          fullWidth
          {...register("email", {
            required: "Dit veld is verplicht",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email is niet correct",
            },
          })}
          error={!!errors.email}
          helperText={errors?.email?.message}
        />
        <TextField
          label="Telefoon of gsm nummer"
          variant="standard"
          fullWidth
          {...register("phonenumber", {
            required: false,
            pattern: {
              value: /^(?:\+32|0)\d([\/\s]?\d{2}[\s.-]?){3}\d{2}$/i,
              message: "Telefoonnummer is niet correct",
            },
          })}
          error={!!errors.phonenumber}
          helperText={errors?.phonenumber?.message}
        />
        <TextField
          label="Onderwerp"
          variant="standard"
          fullWidth
          {...register("subject", { required: "Dit veld is verplicht" })}
          error={!!errors.subject}
          helperText={errors?.subject?.message}
        />
        <TextField
          fullWidth
          multiline
          required
          variant="standard"
          label="Bericht"
          InputProps={{
            rows: 5,
            style: { color: "#606160" },
          }}
          {...register("message", { required: "Dit veld is verplicht" })}
          error={!!errors.message}
          helperText={errors?.message?.message}
        />
        <Button type="submit">Versturen</Button>
      </form>
    </StyledContactForm>
  );
}
