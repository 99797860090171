import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    background: {
      default: "#F7F7F7",
    },
    primary: {
      main: "#B6C561",
      contrastText: "#F7F7F7",
    },
    secondary: {
      main: "#333333",
      light: "#606160",
    },
    error: { main: "#a83632" },
    warning: { main: "#c7993e" },
  },
  typography: {
    fontFamily: "'Raleway', sans-serif",
    h1: {
      color: "#F7F7F7",
      fontSize: "70px",
      fontWeight: "800",
      [`@media screen and (max-width: 1280px)`]: {
        fontSize: "35px",
        lineHeight: "50px",
      },
    },
    h2: {
      color: "#F7F7F7",
      fontSize: "60px",
      fontWeight: "800",
      [`@media screen and (max-width: 1280px)`]: {
        fontSize: "25px",
        lineHeight: "50px",
      },
    },
    h3: {
      color: "#333333",
      fontSize: "1.6rem",
      letterSpacing: "3px",
      textTransform: "uppercase",
      fontWeight: "700",
      [`@media screen and (max-width: 1280px)`]: {
        fontSize: "1.2rem",
      },
    },
    h4: {
      color: "#606160",
      fontSize: "0.8rem",
      lineHeight: "1.4",
      width: "100%",
    },
    h5: {
      color: "#262626",
      fontSize: "0.9rem",
      lineHeight: "1.4",
      fontWeight: "bold",
    },
    h6: {
      color: "#262626",
      textTransform: "uppercase",
      fontSize: "1.2rem",
      fontWeight: "100",
    },
    subtitle1: {
      color: "#606160",
      fontSize: "1rem",
      fontWeight: "500",
      paddingBlock: "1rem",
    },
    caption: {
      color: "#B6C561",
      textAlign: "start",
      fontSize: "1.2rem",
      paddingBlock: ".5rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "16px",
      color: "#262626",
      lineHeight: "1.4",
      fontWeight: "300",
      paddingBlock: "1rem",
    },
    body2: {
      color: "#606160",
      fontSize: ".9rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: "auto",
          height: "auto",
          color: "#F7F7F7",
          background: "#B6C561",
          fontSize: ".7rem",
          fontWeight: "bold",
          fontFamily: "'Raleway', sans-serif",
          padding: ".75rem",
          margin: "0",
          "&:hover": {
            background: "#B6C561",
            cursor: "pointer",
            color: "#262626",
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: "#B6C561",
          fontSize: "1.4rem",
          width: "min-content",
        },
      },
    },
  },
});
