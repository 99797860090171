import { Box, TextField, Button } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useProfile } from "../../../Hooks/useProfile";
import { User } from "../../../Models/User";
import { StyledNewUserForm } from "./NewUserForm.styled";

type NewUserFormProps = {
  onSave: (data: User) => void;
};

export const NewUserForm = ({ onSave }: NewUserFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();

  return (
    <StyledNewUserForm>
      <form onSubmit={handleSubmit(onSave)}>
        <Box className="input-small">
          <TextField
            label="Voornaam"
            variant="standard"
            {...register("firstName", { required: "Dit veld is verplicht" })}
            error={!!errors.firstName}
            helperText={errors?.firstName?.message}
          />
          <TextField
            label="Achternaam"
            variant="standard"
            {...register("lastName", { required: "Dit veld is verplicht" })}
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
          />
        </Box>

        <TextField
          label="E-mailadres"
          variant="standard"
          fullWidth
          {...register("username", {
            required: "Dit veld is verplicht",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email is niet correct",
            },
          })}
          error={!!errors.username}
          helperText={errors?.username?.message}
        />
        <TextField
          label="Wachtwoord"
          variant="standard"
          fullWidth
          {...register("password", {
            required: "Dit veld is verplicht",
          })}
          error={!!errors.username}
          helperText={errors?.username?.message}
        />
        <input type="text" value="Beheerder" hidden {...register("role")} />
        <Button type="submit">Versturen</Button>
      </form>
    </StyledNewUserForm>
  );
};
