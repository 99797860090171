// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Collapse, Menu, MenuItem } from "@mui/material";
import ReactShadowScroll from "react-shadow-scroll";
import { StyledNavBar } from "./Navbar.styled";
import NavbarButton from "./NavbarButton";
import OverlayComponent from "../Overlay/Overlay";
import { OverlayProps } from "../../../Models/Props/OverlayProps";
import { defaultOverlayProps } from "../Shared.helper";
import { LoginComponent } from "../../Login/Login";
import { useProfile } from "../../../Hooks/useProfile";
import { StyledImage, StyledLink } from "../Shared.styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { pages } from "../../../globals/constants";
import OpeningHoursComponent from "../../OpeningHours/OpeningHours";

function NavBarComponent() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [overLayProps, setOverLayProps] =
    useState<OverlayProps>(defaultOverlayProps);
  const { user, logout } = useProfile();

  useEffect(() => {
    window.onscroll = function () {
      window.scrollY > 50 ? setScrolled(true) : setScrolled(false);
    };
  }, []);

  const handleOpenOverlay = (title: string, component: JSX.Element) => {
    setOverlayOpened(true);

    const updatedValue: OverlayProps = { title, component };
    setOverLayProps((overLayProps) => ({
      ...overLayProps,
      ...updatedValue,
    }));
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {overlayOpened ? (
        <OverlayComponent
          isOverlayOpened={overlayOpened}
          overLayProps={overLayProps}
          onClose={() => setOverlayOpened(false)}
        />
      ) : (
        <></>
      )}
      <ReactShadowScroll>
        <StyledNavBar
          $shadow={scrolled ? "rgba(60, 64, 67, 0.3) 0px 4px 6px 0px" : "none"}
          isMenuOpened={isMenuOpened}
        >
          <Box className="navbar-button-wrapper">
            {pages.map((page) => (
              <NavbarButton
                buttonProps={page}
                key={page.name}
                onClick={() => {
                  page.name === "Openingsuren" &&
                    handleOpenOverlay(
                      "Openingsuren",
                      <OpeningHoursComponent />
                    );
                }}
              />
            ))}
          </Box>
          {user ? (
            <>
              <Avatar
                alt="profile"
                variant="square"
                className="avatar"
                onClick={handleMenu}
              >
                {user?.firstName[0]}
              </Avatar>
              <Menu
                id="menu-appbar"
                disableScrollLock={true}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <StyledLink to="/users" className="profile-btn">
                    Beheer accounts
                  </StyledLink>
                </MenuItem>
                <MenuItem onClick={logout}>Uitloggen</MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              className="loginBtn"
              onClick={() =>
                handleOpenOverlay(
                  "Login",
                  <LoginComponent onClose={() => setOverlayOpened(false)} />
                )
              }
            >
              Login
            </Button>
          )}
          <Box className="header">
            <Box className="image-wrapper">
              <StyledImage src={require("../../../Images/Main/Logo.png")} />
            </Box>
            {isMenuOpened ? (
              <FontAwesomeIcon
                icon={faXmark}
                className="icon"
                size={"2xl"}
                onClick={() => setIsMenuOpened(false)}
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="icon"
                size={"2xl"}
                onClick={() => setIsMenuOpened(true)}
              />
            )}
          </Box>
          <Collapse in={isMenuOpened} className="collapsable">
            <Box className="menu">
              <Box className="navbar-button-wrapper">
                {pages.map(
                  (page) =>
                    page.name !== "" && (
                      <NavbarButton
                        buttonProps={page}
                        key={page.name}
                        onClick={() => {
                          page.name === "Openingsuren" &&
                            handleOpenOverlay(
                              "Openingsuren",
                              <OpeningHoursComponent />
                            );
                        }}
                      />
                    )
                )}
              </Box>
            </Box>
          </Collapse>
        </StyledNavBar>
      </ReactShadowScroll>
    </>
  );
}

export default NavBarComponent;
