import Image from "mui-image";
import { StyledPictureWrapper } from "./StyledImageGrid";
import { Box } from "@mui/material";
import { MulterFile } from "../../../Models/MulterFile";

type ImageGridProps = {
  files?: MulterFile[];
};

export function ImageGrid({ files }: ImageGridProps) {
  const handleData = (file: MulterFile) => {
    console.log(file);
  };

  return (
    <>
      {files && files.length > 0 && (
        <StyledPictureWrapper>
          <Box className={`grid grid-${files.length}`}>
            {files.map((file, index) => (
              <Box className={`image image-${index}`}>
                <>{handleData(file)}</>
                <Image src={`data:image/png;base64, ${file}`} />
              </Box>
            ))}
          </Box>
        </StyledPictureWrapper>
      )}
    </>
  );
}
