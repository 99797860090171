import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material";
import { Image } from "mui-image";
import { Link } from "react-router-dom";
import { theme } from "../../globals/theme";

type FontProps = {
  $color?: string;
  $size?: string;
};

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<FontProps>(
  ({ $color, $size }) => ({
    color: $color ?? "inherit",
    fontSize: "1.2rem",
    cursor: "pointer",
    width: $size ?? "2rem",
    height: $size ?? "2rem",
    display: "inline-block",
  })
);

export const StyledImage = styled(Image)({
  backgroundSize: "cover",
});

export const StyledLink = styled(Link)({
  fontWeight: "bold",
  textDecoration: "none",
  color: theme.palette.primary.main,
});
