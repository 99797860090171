import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  format,
} from "date-fns";
import { ClosedDateRange } from "../../Models/ClosedDateRange";
import { getDateCss } from "../Utils";
import { StyledDate } from "./Calander.styled";

type DaysProps = {
  activeDate: Date;
  closedDateRanges: ClosedDateRange[];
  startDate: Date | undefined;
  endDate: Date | undefined;
  selectStartDate: (date: Date | undefined) => void;
  selectEndDate: (date: Date | undefined) => void;
};

export const CalanderDays = ({
  activeDate,
  closedDateRanges,
  startDate,
  endDate,
  selectStartDate,
  selectEndDate,
}: DaysProps) => {
  const startOfTheSelectedMonth = startOfMonth(activeDate);
  const endOfTheSelectedMonth = endOfMonth(activeDate);
  const weekStart = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
  const weekEnd = endOfWeek(endOfTheSelectedMonth);

  const generateDatesForCurrentWeek = (date: Date) => {
    let currentDate = date;
    const week = [];
    for (let day = 0; day < 7; day++) {
      const cloneDate = currentDate;

      const css = getDateCss(closedDateRanges, cloneDate, startDate!, endDate!);

      const handleDateSelect = (clickedDate: Date) => {
        if (clickedDate === startDate) selectStartDate(undefined);
        !startDate ? selectStartDate(clickedDate) : selectEndDate(clickedDate);
      };

      week.push(
        <StyledDate
          className="date"
          $borderRadius={css.borderRadius}
          $color={css.color}
          $background={css.background}
          onClick={() => handleDateSelect(cloneDate)}
        >
          {format(currentDate, "d")}
        </StyledDate>
      );
      currentDate = addDays(currentDate, 1);
    }
    return <>{week}</>;
  };

  let currentDate = weekStart;

  const allWeeks = [];

  while (currentDate <= weekEnd) {
    allWeeks.push(generateDatesForCurrentWeek(currentDate));
    currentDate = addDays(currentDate, 7);
  }

  return <>{allWeeks}</>;
};
