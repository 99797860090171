import React from "react";
import { NewsArticle } from "../../Models/NewsArticle";
import { format } from "../Utils";
import { StyledNews } from "./News.styled";
import { ImageGrid } from "../Shared/ImageGrid/ImageGrid";
import { useProfile } from "../../Hooks/useProfile";
import { Button } from "@mui/material";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { adminName } from "../../globals";
import { Body, H3, H4 } from "../Shared/Typography";

type ArticleProps = {
  newsArticle?: NewsArticle;
  $color?: string;
  onDelete: (id: number) => void;
};

function NewsArticleComponent({ $color, newsArticle, onDelete }: ArticleProps) {
  const { user } = useProfile();

  if (!newsArticle) return <></>;

  return (
    <StyledNews $color={$color}>
      {user && user.role === adminName && (
        <Button onClick={() => onDelete(newsArticle.id!)}>
          <StyledFontAwesomeIcon icon={faTrash} />
        </Button>
      )}
      <H3>{newsArticle?.title}</H3>
      <Body>{newsArticle?.content}</Body>
      <ImageGrid files={newsArticle.files} />
      <H4>Geschreven op {newsArticle && format(new Date(newsArticle.date))}</H4>
    </StyledNews>
  );
}

export default NewsArticleComponent;
