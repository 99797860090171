import { useEffect, useState } from "react";
import BlockComponent from "../Shared/Block/Block";
import NewsArticleComponent from "./NewsArticle";
import { useProfile } from "../../Hooks/useProfile";
import { adminName } from "../../globals";
import { OverlayProps } from "../../Models/Props/OverlayProps";
import { defaultOverlayProps } from "../Shared/Shared.helper";
import OverlayComponent from "../Shared/Overlay/Overlay";
import { AddNewsComponent } from "./AddNews";
import { Button } from "@mui/material";
import { NewsArticle } from "../../Models/NewsArticle";
import { useNews } from "../../Hooks/useNews";
import { NewsArticleInputProps } from "../../Models/NewsArticleInputProps";
import { Message } from "../Shared/Message/Message";
import { Body } from "../Shared/Typography";

function NewsComponent() {
  const { user } = useProfile();
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);
  const [newsArticles, setNewsArticles] = useState<NewsArticle[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const [overLayProps, setOverLayProps] =
    useState<OverlayProps>(defaultOverlayProps);
  const { fetchNews, removeNews, addNews } = useNews();
  const handleOpenOverlay = (title: string, component: JSX.Element) => {
    setOverlayOpened(true);

    const updatedValue: OverlayProps = { title, component };
    setOverLayProps((overLayProps) => ({
      ...overLayProps,
      ...updatedValue,
    }));
  };

  useEffect(() => {
    updateItems();
    //eslint-disable-next-line
  }, []);

  const updateItems = () => {
    fetchNews().then((res) => {
      setNewsArticles(
        res.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        )
      );
    });
  };

  const handleDelete = async (id: number) => {
    const status = await removeNews(id);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("artikel verwijderd");
      updateItems();
    }
    setShowMessage(true);
  };

  const onSubmit = async (data: NewsArticleInputProps) => {
    if (data.files && data.files.length > 5) {
      setResponseStatus(406);
      setMessage("Je kan maximaal 5 foto's uploaden.");
      setShowMessage(true);
      return;
    }

    const status = await addNews(data);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Nieuwsartikel opgeslagen!");
      setTimeout(() => {
        setOverlayOpened(false);
      }, 1000);

      updateItems();
    }
    setShowMessage(true);
  };

  return (
    <>
      {overlayOpened ? (
        <OverlayComponent
          isOverlayOpened={overlayOpened}
          overLayProps={overLayProps}
          onClose={() => setOverlayOpened(false)}
        />
      ) : (
        <></>
      )}
      <BlockComponent title="Nieuws & activiteiten">
        <Message
          status={responseStatus}
          showMessage={showMessage}
          onFinish={() => {
            setShowMessage(false);
            setMessage("");
          }}
        >
          {message}
        </Message>
        {user && user.role === adminName && (
          <Button
            onClick={() =>
              handleOpenOverlay(
                "Nieuw artikel aanmaken",
                <AddNewsComponent onUpload={(data) => onSubmit(data)} />
              )
            }
          >
            Nieuw Artikel aanmaken
          </Button>
        )}
        {newsArticles.length === 0 ? (
          <Body className="noItems-message">
            Plantenkwekerij Bart Blondeel heeft nog geen nieuwsberichten of
            activiteiten geplaatst. Hou deze pagina zeker in de gaten voor
            toekomstige berichten of contacteer Plantenkwekerij Bart Blondeel
            voor meer informatie.
          </Body>
        ) : (
          <>
            {newsArticles.map((article) => (
              <NewsArticleComponent
                $color={article.type === "activity" ? "#617ac5" : undefined}
                newsArticle={article}
                onDelete={(id) => handleDelete(id)}
                key={article.id}
              />
            ))}
          </>
        )}
      </BlockComponent>
    </>
  );
}

export default NewsComponent;
