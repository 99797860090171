import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledNewUserForm = styled(Box)({
  width: "40vw",
  padding: "3rem",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
    ".input-small": {
      display: "flex",
      width: "100%",
      gap: "10%",
      ">div": {
        width: "45%",
      },
    },
    ">div p": {
      fontSize: ".9rem",
      fontWeight: "100",
    },
  },
  "@media (max-width: 1280px)": {
    width: "100%",
    padding: "1rem",
  },
});
