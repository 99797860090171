import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../../globals/theme";

type MessageProps = {
  background: string;
};

export const StyledMessage = styled(Box)(({ background }: MessageProps) => ({
  position: "fixed",
  top: "5rem",
  zIndex: "99999",
  width: "fit-content",
  padding: ".5rem 2rem",
  minWidth: "30rem",
  textAlign: "center",
  background: background,
  borderRadius: "5px",
  margin: "auto",
  h6: {
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
  },
  "@media (max-width: 1280px)": {
    width: "85%",
    minWidth: "auto",
  },
}));
