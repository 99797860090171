import axios, { AxiosResponse } from "axios";
import { ContactFormInput } from "../Models/ContactFormInput";
import { serverUrl } from "../globals";

export const createEmail = async (email: ContactFormInput): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.post<ContactFormInput>(
      `${serverUrl}/email/create`,
      email,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
