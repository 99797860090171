import axios, { AxiosResponse } from "axios";
import { UserStore } from "../Hooks/useProfile";
import { User } from "../Models/User";
import { Set } from "../Models/Set";
import { InputValues } from "../Models/InputValues";
import { serverUrl } from "../globals";

const getUserToken = async (inputData: InputValues) => {
  const { data } = await axios.post<User>(
    `${serverUrl}/auth/login`,
    { username: inputData.username, password: inputData.password },
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );
  return data;
};

export const getUser = async (set: Set<UserStore>, inputData: InputValues) => {
  const token = await getUserToken(inputData);

  try {
    const response: AxiosResponse = await axios.get<User>(
      `${serverUrl}/auth/profile`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    set((state) => ({ ...state, user: response.data }));
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const getAllUsers = async () => {
  const { data } = await axios.get<User[]>(
    `${serverUrl}/user/getall`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return data;
};

export const createUser = async (user: User): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.post<User>(
      `${serverUrl}/user/create`,
      user,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteUser = async (
  id: number
) => {
  try {
    const response: AxiosResponse = await axios.delete<User>(
      `${serverUrl}/user/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const updateUser = async () => {return 0}