import { Review } from "../../Models/Review";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";
import { format } from "../Utils";
import { Box, Button, Rating } from "@mui/material";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { adminName } from "../../globals";
import { useProfile } from "../../Hooks/useProfile";
import { Body, H4, H5 } from "../Shared/Typography";

type ReviewProps = {
  review: Review;
  onDelete: (id: number) => void;
};

function ReviewComponent({ review, onDelete }: ReviewProps) {
  const { user } = useProfile();

  return (
    <Box className="review">
      {user && user.role === adminName && (
        <Button onClick={() => onDelete(review.id!)}>
          <StyledFontAwesomeIcon icon={faTrash} />
        </Button>
      )}

      <H5>{review.name}</H5>
      <Rating
        name="read-only"
        value={review.stars}
        color={"#262626"}
        readOnly
      />
      <Body>{review.message}</Body>
      <H4 className="date">{format(new Date(review.date))}</H4>
    </Box>
  );
}

export default ReviewComponent;
