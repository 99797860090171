export const seasonalCards = [
  {
    title: "maart - april",
    description:
      "In maart en april hebben we viooltjes, primula's, onze vaste planten, bloembollen in pot en struiken.",
  },
  {
    title: "mei - juni",
    description:
      "Éénjarigen, stekperkplanten, geraniums, terrasplanten, hangpotten...",
  },
  {
    title: "juli - augustus",
    description:
      "Kom in juli en augustus langs in Tielt-Winge voor onze bloeiende vaste planten, struiken, ...",
  },
  {
    title: "september - oktober",
    description:
      "Heideplanten, chrysanten, vaste planten, sierstruiken, winterviolen, bloembollen, ...",
  },
  {
    title: "november - december",
    description:
      "Kleinfruit, fruitboompjes, rozen, kerstbomen, kerstdecoratie, ...",
  },
];

export const pages = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Nieuws",
    link: "/news",
  },
  {
    name: "Galerij",
    link: "/gallery",
  },
  {
    name: "",
    link: "/",
    imageSrc: "../../../public/Images/Main/Logo.png",
    width: "9vw",
    height: "5vh",
    hoverStyle: "none",
  },
  {
    name: "Recensies",
    link: "/reviews",
  },
  {
    name: "Contact",
    link: "/contact",
  },
  {
    name: "Openingsuren",
    link: "/any",
  },
];
