import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { theme } from "../../globals/theme";

export const StyledContact = styled(Box)({
  display: "flex",
  ".content": {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingLeft: "2rem",
    justifyContent: "space-between",
    ".info-wrapper": {
      gap: "1rem",
      display: "flex",
      flexDirection: "column",
      h4: {
        width: "fit-content",
      },
      ".email-button": {
        display: "flex",
        gap: ".5rem",
        ">h4": {
          cursor: "pointer",
          color: "#B6C561",
          textTransform: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    ".button-wrapper": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      ".contact-button": {
        background: theme.palette.secondary.main,
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  iframe: {
    width: "40vw",
    height: "20vw",
    border: "none",
    borderRadius: "5px",
    boxShadow: "1px 2px 9px #a3a3a3",
  },
  "@media (max-width: 1280px)": {
    flexDirection: "column",
    width: "100%",
    iframe: {
      width: "100%",
      height: "15rem",
    },
    ".content": {
      width: "100%",
      margin: "auto",
      padding: "1rem 0",
      alignItems: "center",
      ".button-wrapper": {
        paddingBlock: "1rem",
        flexDirection: "row",
      },
    },
  },
});
