import { create } from "zustand";
import { ContactFormInput } from "../Models/ContactFormInput";
import { createEmail } from "../Repositories/EmailRepository";

export interface EmailStore {
  sendEmail: (email: ContactFormInput) => Promise<number | undefined>;
}

export const useEmail = create<EmailStore>(() => ({
  sendEmail: (email) => {
    return createEmail(email);
  },
}));
