import { ReactNode } from "react";
import { StyledBlock } from "./Block.styled";
import { H3, SubTitle } from "../Typography";

type Props = {
  children?: ReactNode;
  title: string;
  description?: string;
};

function BlockComponent({ children, title, description }: Props) {
  return (
    <StyledBlock>
      <H3>{title}</H3>
      <SubTitle>{description}</SubTitle>
      <>{children}</>
    </StyledBlock>
  );
}

export default BlockComponent;
