import { Box } from "@mui/material";
import { startOfWeek, format, addDays } from "date-fns";
import { nlBE } from "date-fns/locale";
import { ReactNode } from "react";

type WeekdaysProps = {
  activeDate: Date;
  defaultClosedDays: number[];
  saveWeekday: (day: number) => void;
  removeWeekday: (day: number) => void;
};

export const CalanderWeekdays = ({
  activeDate,
  defaultClosedDays,
  saveWeekday,
  removeWeekday,
}: WeekdaysProps) => {
  const weekStartDate = startOfWeek(activeDate, { weekStartsOn: 1 });
  const weekDays: ReactNode[] = [];
  for (let day = 0; day < 7; day++) {
    const selected = defaultClosedDays.includes(day + 1);
    weekDays.push(
      <Box
        className={`weekDay ${selected ? "weekday-selected" : ""}`}
        onClick={() =>
          selected ? removeWeekday(day + 1) : saveWeekday(day + 1)
        }
      >
        {format(addDays(weekStartDate, day), "E", { locale: nlBE })}
      </Box>
    );
  }

  return <Box className="wrapper">{weekDays}</Box>;
};
