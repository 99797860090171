import { useEffect, useState } from "react";
import { StyledMessage } from "./Massage.styled";
import { Fade } from "@mui/material";
import { H6 } from "../Typography";
import { theme } from "../../../globals/theme";

type MessageProps = {
  status: number;
  children?: string;
  showMessage: boolean;
  onFinish: () => void;
};

export function Message({
  status,
  children,
  showMessage,
  onFinish,
}: MessageProps) {
  const [type, setType] = useState<"success" | "warning" | "error">("success");

  useEffect(() => {
    switch (status) {
      case 200:
      case 201:
        setType("success");
        break;
      case 400:
      case 404:
      case 500:
      case 401:
      case 406:
      case 409:
        setType("error");
        break;
    }
  }, [status]);

  useEffect(() => {
    setTimeout(() => {
      onFinish();
    }, 5000);
  }, [showMessage]);

  const getColor = () => {
    let color = "";

    switch (type) {
      case "error":
        color = theme.palette.error.main;
        break;
      case "success":
        color = theme.palette.primary.main;
        break;
      case "warning":
        color = theme.palette.warning.main;
        break;
    }

    return color;
  };

  return (
    <Fade in={showMessage}>
      <StyledMessage background={getColor()}>
        <H6>
          {children === "" ? `Er is een fout opgetreden ${status}` : children}
        </H6>
      </StyledMessage>
    </Fade>
  );
}
