import { create } from "zustand";
import { GalleryImage } from "../Models/Image";
import {
  getGallery,
  createImage,
  deleteImage,
} from "../Repositories/GalleryRepository";

export interface ImageStore {
  fetchGallery: () => Promise<GalleryImage[]>;
  addImages: (images: FileList) => Promise<number | undefined>;
  removeImage: (id: number) => Promise<number | undefined>;
}

export const useGallery = create<ImageStore>(() => ({
  fetchGallery: () => {
    return getGallery();
  },
  addImages: (images) => {
    return createImage(images);
  },
  removeImage: (id) => {
    return deleteImage(id);
  },
}));
