import { StyledAddNews } from "./News.styled";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Button,
  Input,
  Box,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NewsArticleInputProps } from "../../Models/NewsArticleInputProps";
import { H4 } from "../Shared/Typography";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { theme } from "../../globals/theme";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";

type AddNewsProps = {
  onUpload: (data: NewsArticleInputProps) => void;
};

export function AddNewsComponent({ onUpload }: AddNewsProps) {
  const [fileCount, setFileCount] = useState<number>(0);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<NewsArticleInputProps>({
    defaultValues: {
      type: "newsarticle",
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const count = event.currentTarget.files?.length;
    if (count) setFileCount(count);
  };

  let date = new Date().toISOString();
  const formatedDate = date.split(".")[0].split("T").join(" ");

  const onSubmit = (data: NewsArticleInputProps) => {
    onUpload(data);
  };

  return (
    <StyledAddNews>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Titel"
          variant="standard"
          fullWidth
          {...register("title", { required: "Dit veld is verplicht" })}
          error={!!errors.title}
          helperText={errors?.title?.message}
        />
        <TextField
          label="Bericht"
          variant="standard"
          multiline
          minRows={7}
          maxRows={7}
          fullWidth
          {...register("content", { required: "Dit veld is verplicht" })}
          error={!!errors.content}
          helperText={errors?.content?.message}
        />
        <Controller
          rules={{ required: true }}
          control={control}
          name="type"
          render={({ field }) => (
            <RadioGroup {...field} className="radioGroup">
              <FormControlLabel
                className="formControl"
                value="newsarticle"
                control={<Radio defaultChecked />}
                label="Nieuws artikel"
              />
              <FormControlLabel
                className="formControl"
                value="activity"
                control={<Radio />}
                label="Activiteit"
              />
            </RadioGroup>
          )}
        />

        <Box className="fileInput">
          <Input
            type="file"
            {...register("files")}
            inputProps={{ multiple: true }}
            onChange={handleChange}
          />
          <Typography className="input-label">Foto('s) uploaden</Typography>
          <Box className="icon-wrapper">
            <StyledFontAwesomeIcon
              icon={regular("image")}
              $color={theme.palette.primary.contrastText}
            />
          </Box>
        </Box>
        <H4>Je hebt momenteel [{fileCount}/5] foto's gekozen</H4>
        <input
          type="datetime-local"
          value={formatedDate}
          hidden
          {...register("date")}
        />
        <Button type="submit">Opslaan</Button>
      </form>
    </StyledAddNews>
  );
}
