import { Button, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import { theme } from "../../../globals/theme";

type navBarButtonprops = {
  $hoverstyle?: string;
  $imagesrc?: string;
  $width?: string;
  $height?: string;
};

type NavBarProps = {
  $shadow: string;
  isMenuOpened: boolean;
};

export const StyledNavBar = styled(Toolbar)<NavBarProps>(
  ({ $shadow, isMenuOpened }) => ({
    position: "fixed",
    background: "#F7F7F7",
    width: "100%",
    top: "0",
    height: "auto",
    display: "flex",
    boxShadow: isMenuOpened ? "rgba(60, 64, 67, 0.3) 0px 4px 6px 0px" : $shadow,
    zIndex: "998",
    ".loginBtn": {
      background: "none",
      color: theme.palette.secondary.main,
      position: "absolute",
      right: "5vw",
      "&:hover": {
        color: "#c3c4c7",
      },
    },
    ".navbar-button-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      marginInline: "auto",
      gap: "2rem",
      paddingLeft: "2rem",
    },
    ".avatar": {
      display: "flex",
      position: "absolute",
      right: "5vw",
      alignItems: "center",
      background: theme.palette.primary.main,
      justifyContent: "center",
      cursor: "pointer",
    },
    ".profile-btn": {
      color: theme.palette.secondary.main,
    },
    ".header": {
      position: "relative",
      display: "none",
      alignItems: "center",
      width: "100%",
      paddingBlock: "1rem",
      ".icon": {
        position: "absolute",
        right: "2rem",
      },
      ".image-wrapper": {
        height: "4rem",
        width: "50%",
        margin: "auto",
      },
    },
    ".collapsable": {
      display: "none",
    },
    ".menu": {
      height: "100%",
      width: "100%",
      paddingBottom: "4rem",
      ".navbar-button-wrapper": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        padding: "0",
      },
    },
    "@media (max-width: 1280px)": {
      padding: "0",
      flexDirection: "column",
      ".loginBtn": {
        bottom: "1rem",
        right: "1rem",
        display: isMenuOpened ? "flex" : "none",
      },
      ".avatar": {
        bottom: "1rem",
        right: "1rem",
        display: isMenuOpened ? "flex" : "none",
      },
      ".navbar-button-wrapper": {
        display: "none",
      },
      ".header": {
        display: "flex",
      },
      ".collapsable": {
        display: "initial",
      },
    },
  })
);

export const StyledNavbarButton = styled(Button)<navBarButtonprops>(
  ({ $hoverstyle, $height, $width }) => ({
    borderRadius: "0",
    color: "#545454",
    background: "none",
    letterSpacing: "1px",
    fontWeight: "bold",
    width: $width ?? "auto",
    height: $height ?? "auto",
    fontSize: ".8rem",
    padding: "0.5rem 0.25rem",
    "&:hover": {
      cursor: "pointer",
      background: "none",
      color: "#262626",
      borderBottom: $hoverstyle ?? "2px solid #262626",
    },
  })
);
