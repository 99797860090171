import { useEffect, useState } from "react";
import BlockComponent from "../Shared/Block/Block";
import ReviewComponent from "./Review";
import { StyledReviews } from "./Reviews.styled";
import { Review } from "../../Models/Review";
import { useReviews } from "../../Hooks/useReviews";
import { Controller, useForm } from "react-hook-form";
import { Message } from "../Shared/Message/Message";
import { Box, Button, Rating, TextField } from "@mui/material";
import { Body, H5 } from "../Shared/Typography";

function ReviewsComponent() {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const { fetchReviews, saveReview, removeReview } = useReviews();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Review>();
  useEffect(() => {
    updateItems();
    //eslint-disable-next-line
  }, []);

  const updateItems = () => {
    fetchReviews().then((res) => {
      setReviews(res);
    });
  };

  const onSubmit = async (data: Review) => {
    const status = await saveReview(data);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Je review is opgeslagen!");
      updateItems();
    }
    setShowMessage(true);
  };

  const handleRemove = async (id: number) => {
    const status = await removeReview(id);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Review verwijderd");
      updateItems();
    }
    setShowMessage(true);
  };

  let date = new Date().toISOString();
  const formatedDate = date.split(".")[0].split("T").join(" ");

  return (
    <>
      <BlockComponent title="Recensies">
        <Message
          status={responseStatus}
          showMessage={showMessage}
          onFinish={() => {
            setShowMessage(false);
            setMessage("");
          }}
        >
          {message}
        </Message>
        {reviews.length === 0 && (
          <Body className="noItems-message">
            PlantenKwekerij Bart Blondeel heeft nog geen recensies. Schrijf nu
            een recensie!
          </Body>
        )}
        <StyledReviews>
          <Box className="wrapper">
            {reviews.length > 0 &&
              reviews.map((review) => (
                <ReviewComponent
                  review={review}
                  onDelete={(id) => handleRemove(id)}
                />
              ))}
          </Box>
        </StyledReviews>
      </BlockComponent>
      <BlockComponent title="Schrijf een recensie">
        <StyledReviews>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Naam"
              fullWidth
              variant="standard"
              {...register("name", { required: "Dit veld is verplicht" })}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
            <TextField
              label="E-mailadres"
              fullWidth
              variant="standard"
              {...register("email", {
                required: "Dit veld is verplicht",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email is niet correct",
                },
              })}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
            <H5>Score *</H5>
            <Controller
              control={control}
              name={"stars"}
              defaultValue={-1}
              rules={{ required: "Dit veld is verplicht" }}
              render={({ field: { onChange, value } }) => (
                <Rating
                  className="rating"
                  onChange={onChange}
                  value={Number(value)}
                />
              )}
            />
            <TextField
              fullWidth
              multiline
              variant="standard"
              label="Recensie"
              InputProps={{
                rows: 5,
                style: { color: "#606160" },
              }}
              {...register("message", { required: "Dit veld is verplicht" })}
              error={!!errors.message}
              helperText={errors?.message?.message}
            />
            <input
              type="datetime-local"
              value={formatedDate}
              hidden
              {...register("date")}
            />
            <Button type="submit">Recensie plaatsen</Button>
          </form>
        </StyledReviews>
      </BlockComponent>
    </>
  );
}

export default ReviewsComponent;
