import { Box, styled } from "@mui/material";

type UsersProps = {
  isAddButton?: boolean;
};

export const StyledUsers = styled(Box)(({ isAddButton }: UsersProps) => ({
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
  padding: "0",
  paddingTop: "2rem",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledCard = styled(Box)(({ isAddButton }: UsersProps) => ({
  width: "25rem",
  height: "7.5rem",
  boxShadow: "1px 2px 9px #a3a3a3",
  display: "flex",
  paddingBlock: "1rem",
  alignItems: "center",
  borderRadius: "5px",
  cursor: "pointer",
  "&:hover >*": {
    color: isAddButton && "#B6C561 !important",
  },
  ".icon": {
    width: "15%",
    height: "40%",
    padding: "1rem",
  },
  ".user-info>*": {
    padding: "0",
  },
  ".btn-wrapper": {
    display: "flex",
    gap: "1rem",
    padding: ".5rem .25rem",
    boxSizing: "border-box",
    ".btn-changepass": {
      background: "#c7993e",
      color: "#FFF",
      visibility: "hidden",
    },
    ".btn-remove": {
      background: "#a83632",
      color: "#FFF",
    },
    ".disabled": {
      visibility: "hidden",
    },
  },
  ".add-account": {
    fontWeight: "bold",
    fontSize: "1.5rem",
    textAlign: "center",
    padding: "1rem",
    color: "rgba(38, 38, 38, 0.7)",
  },
  "@media (max-width: 1280px)": {
    width: "100%",
    flexDirection: "column",
    height: "auto",
    ".icon": {
      width: "10%",
    },
  },
}));
