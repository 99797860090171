import axios, { AxiosResponse } from "axios";
import { Set } from "../Models/Set";
import { CalanderStore } from "../Hooks/useCalander";
import { ClosedDateRange } from "../Models/ClosedDateRange";
import { OpeningDays } from "../Models/OpeningDays";
import { serverUrl } from "../globals";
import { OpeningHour } from "../Models/OpeningHours";

export const getAllClosedDateRanges = async (set: Set<CalanderStore>) => {
  const { data } = await axios.get<ClosedDateRange[]>(
    `${serverUrl}/calander/getall`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  set((state) => ({ ...state, reviews: data }));
  return data;
};

export const getDefaultClosedDays = async (set: Set<CalanderStore>) => {
  const { data } = await axios.get<number[]>(
    `${serverUrl}/calander/getDefaultClosedDays`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return data;
};

export const createClosedDateRange = async (
  closedDateRange: ClosedDateRange
) => {
  try {
    const response: AxiosResponse = await axios.post<ClosedDateRange>(
      `${serverUrl}/calander/create`,
      closedDateRange,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteClosedDateRange = async (
  closedDateRange: ClosedDateRange
) => {
  try {
    const response: AxiosResponse = await axios.delete<ClosedDateRange>(
      `${serverUrl}/calander/delete/${closedDateRange.id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const createDefaultClosedDate = async (dayIndex: number) => {
  try {
    const response: AxiosResponse = await axios.put<number>(
      `${serverUrl}/calander/createDefaultDates/${dayIndex}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteDefaultClosedDate = async (dayIndex: number) => {
  try {
    const response: AxiosResponse = await axios.delete<number>(
      `${serverUrl}/calander/deleteDefaultClosedDay/${dayIndex}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const getCurrentWeekDays = async () => {
  const { data } = await axios.get<OpeningDays[]>(
    `${serverUrl}/calander/getOpeningDays`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return data;
};

export const updateOpeningHours = async (openingHours: OpeningHour[]) => {
  try {
    const response: AxiosResponse = await axios.post<OpeningHour[]>(
      `${serverUrl}/calander/updateOpeningHours`,
      openingHours,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
