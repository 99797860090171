import { useState } from "react";
import { StyledFontAwesomeIcon, StyledLink } from "../Shared.styled";
import { StyledFooter } from "./Footer.styled";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button } from "@mui/material";
import { SubTitle } from "../Typography";

function FooterComponent() {
  const [hasLiked, setHasLiked] = useState<Boolean>(false);

  const handleLikeButton = () => {
    setHasLiked(!hasLiked);
  };

  return (
    <StyledFooter>
      <Box className="banner banner-top">
        <Button className="likebtn" onClick={handleLikeButton}>
          {hasLiked ? (
            <StyledFontAwesomeIcon $size="1.2rem" icon={solid("heart")} />
          ) : (
            <StyledFontAwesomeIcon $size="1.2rem" icon={regular("heart")} />
          )}
          Vind ik leuk {/*[{likes}]*/}
        </Button>

        <StyledLink to="https://www.facebook.com/plantenbartblondeel/">
          <StyledFontAwesomeIcon $color="#262626" icon={brands("facebook")} />
        </StyledLink>
      </Box>
      <Box className="banner banner-bottom">
        <SubTitle>
          Leuvensesteenweg 69a, 3390 Sint-Joris-Winge 016 63 39 32
        </SubTitle>
        <SubTitle className="text-italic">
          Plantenkwekerij, Sint-Joris-Winge
        </SubTitle>
        <SubTitle>Plantenkwekerij Bart Blondeel</SubTitle>
        <SubTitle className="text-small">
          Plantenkwekerij Bart Blondeel since 2023 - Copyright © All rights
          reserved - Website provided by Wannes Schillebeeckx
        </SubTitle>
      </Box>
    </StyledFooter>
  );
}

export default FooterComponent;
