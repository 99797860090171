import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { theme } from "../../globals/theme";

type CalanderProps = {
  $background?: string;
  $color?: string;
  $borderRadius: string;
};

export const StyledCalander = styled(Box)(() => ({
  width: "35vw",
  border: "1px solid #545454",
  borderRadius: "5px",
  display: "flex",
  flexWrap: "wrap",
  padding: "2rem",
  ".header": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: ".5rem",
    justifyContent: "space-between",
    button: {
      color: theme.palette.primary.contrastText,
      border: "none",
    },
  },
  ".button": {
    width: "10vw",
    background: "transparant",
    color: theme.palette.secondary.main,
    border: "1px solid #262626",
    fontWeight: "bold",
  },
  ".switch": {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#B6C561",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#B6C561",
    },
    "&:hover .MuiSwitch-switchBase": {
      color: "#B6C561",
    },
  },
  ".weekDay": {
    textAlign: "center",
    fontWeight: "bold",
    color: "#545454",
    fontFamily: "'Raleway', sans-serif",
    cursor: "pointer",
    width: "5vw",
    height: "5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50px",
    "&:hover": {
      background: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  ".weekday-selected": {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.contrastText,
  },
  ".wrapper": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBlock: "2vh",
  },
}));

export const StyledDate = styled(Box)(
  ({ $background, $color, $borderRadius }: CalanderProps) => ({
    color: $color ?? "#545454",
    background: $background ?? "transparant",
    borderRadius: $borderRadius,
    width: "5vw",
    height: "5vh",
    marginBlock: "0.2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "'Raleway', sans-serif",
    cursor: "pointer",
    fontWeight: "500",

    "&:hover": {
      background: "#545454",
      borderRadius: "50px",
      color: theme.palette.primary.contrastText,
    },
  })
);

export const StyledButtons = styled(Box)({
  padding: "1rem 2rem 1rem 2rem",
  width: "35vw",
  display: "flex",
  justifyContent: "space-evenly",
  ".btn": {
    padding: ".5rem",
    color: theme.palette.primary.contrastText,
  },
  ".btn-save": {
    background: theme.palette.primary.main,
  },
  ".btn-remove": {
    background: theme.palette.warning.main,
  },
  ".btn-reset": {
    background: theme.palette.error.main,
  },
});

export const StyledCalanderContent = styled(Box)({
  display: "flex",
  gap: "2rem",
});

export const StyledDayConfiguration = styled(Box)({
  width: "30vw",
  border: "1px solid #545454",
  borderRadius: "5px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  paddingInline: "2rem",
  h3: {
    paddingBlock: "1rem",
  },
  ".day-wrapper": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    paddingBlock: "1rem",
    ".day": {
      display: "flex",
      justifyContent: "space-between",
      height: "2.5rem",
      h4: {
        height: "100%",
        width: "min-content",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      ".timepicker": {
        display: "flex",
        width: "70%",
        gap: "1rem",
        ".MuiFormControl-root": {
          label: {
            fontSize: "1rem",
            transform: "translate(14px, 10px) scale(0.85)",
          },
          ".MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        },
        ".MuiInputBase-root": {
          height: "100%",
        },
        h4: {
          width: "2rem",
        },
        button: {
          width: "min-content",
        },
      },
    },
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    button: {
      width: "50%",
    },
  },
});
