import { Box, styled } from "@mui/material";
import { theme } from "../../globals/theme";

type NewsArticleProps = {
  $color?: string;
};

export const StyledNews = styled(Box)(({ $color }: NewsArticleProps) => ({
  width: "40vw",
  marginBlock: "2vh",
  borderRadius: "5px",
  height: "auto",
  minHeight: "10vh",
  boxShadow: "1px 2px 9px #a3a3a3",
  borderLeft: `10px solid`,
  borderColor: $color ?? "#B6C561",
  position: "relative",
  h3: {
    textAlign: "center",
    paddingTop: "1rem",
    paddingInline: "5rem",
  },
  h4: {
    textAlign: "center",
    paddingBlock: ".5rem",
  },
  p: {
    padding: "1rem 5rem",
  },
  button: {
    position: "absolute",
    height: "2rem",
    padding: ".5rem",
    right: "1rem",
    top: "1rem",
    ">svg": {
      height: "100%",
    },
  },
  "@media (max-width: 1280px)": {
    width: "100%",
    h3: {
      paddingInline: "2rem",
    },
    p: {
      padding: "1rem 2rem",
    },
    button: {
      position: "relative",
      inset: "0",
      marginBlock: ".5rem",
    },
  },
}));

export const StyledAddNews = styled(Box)({
  padding: "2rem 5rem",
  width: "50vw",
  boxSizing: "border-box",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  form: {
    display: "flex",
    margin: "auto",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    ">div p": {
      fontSize: ".9rem",
      fontWeight: "100",
    },
    ".radioGroup": {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: "1rem",
      ".formControl": {
        margin: "0",
      },
    },
    "input[type='file']": {
      cursor: "pointer",
      opacity: "0",
      position: "absolute",
      width: "15rem",
      height: "100%",
    },
    ".fileInput": {
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
      width: "min-content",
      cursor: "pointer",
      height: "3rem",
      ".input-label": {
        color: theme.palette.secondary.light,
        width: "10rem",
        padding: "0",
      },
      ".icon-wrapper": {
        background: theme.palette.primary.main,
        height: "100%",
        width: "5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  "@media (max-width: 1280px)": {
    width: "100%",
    padding: "1rem",
    form: {
      button: {
        width: "15rem",
      },
    },
  },
});
