import { Box, Button, Typography } from "@mui/material";
import BlockComponent from "../Shared/Block/Block";
import { StyledCard, StyledUsers } from "./Users.styled";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useProfile } from "../../Hooks/useProfile";
import ErrorPageComponent from "../ErrorPage/ErrorPage";
import { adminName } from "../../globals";
import { Body, H5 } from "../Shared/Typography";
import { User } from "../../Models/User";
import { useEffect, useState } from "react";
import { OverlayProps } from "../../Models/Props/OverlayProps";
import { defaultOverlayProps } from "../Shared/Shared.helper";
import OverlayComponent from "../Shared/Overlay/Overlay";
import { NewUserForm } from "./NewUserForm.tsx/NewUserForm";
import { Message } from "../Shared/Message/Message";

export function UsersComponent() {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);
  const [overLayProps, setOverLayProps] =
    useState<OverlayProps>(defaultOverlayProps);
  const [users, setUsers] = useState<User[]>([]);
  const { user, fetchAllUsers } = useProfile();
  const { saveUser, removeUser } = useProfile();

  useEffect(() => {
    updateItems();
    //eslint-disable-next-line
  }, []);

  const updateItems = () => {
    fetchAllUsers().then((res) => {
      setUsers(res);
    });
  };

  const handleOpenOverlay = (title: string, component: JSX.Element) => {
    setOverlayOpened(true);

    const updatedValue: OverlayProps = { title, component };
    setOverLayProps((overLayProps) => ({
      ...overLayProps,
      ...updatedValue,
    }));
  };

  if (!user || (user && user.role != adminName))
    return (
      <ErrorPageComponent
        title="Je hebt geen rechten om deze pagina te bekijken"
        description="401 Not authorized"
      />
    );

  const onSubmit = async (data: User) => {
    if (doesUsernameExist(data.username!)) {
      setResponseStatus(409);
      setMessage("Er bestaat al een gebruiker met dit e-mailadres");
      setShowMessage(true);
      return;
    }
    const status = await saveUser(data);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Gebruiker aangemaakt!");
      updateItems();
    }
    setShowMessage(true);
  };

  const doesUsernameExist = (username: string) => {
    const user = users.find((_user) => _user.username === username);
    if (user) return true;
    return false;
  };

  const handleDelete = async (id: number) => {
    const status = await removeUser(id);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Gebruiker verwijderd!");
      updateItems();
    }
    setShowMessage(true);
  };

  return (
    <>
      <BlockComponent title="Website beheerders">
        <Message
          status={responseStatus}
          showMessage={showMessage}
          onFinish={() => {
            setShowMessage(false);
            setMessage("");
          }}
        >
          {message}
        </Message>
        <StyledUsers>
          {overlayOpened ? (
            <OverlayComponent
              isOverlayOpened={overlayOpened}
              overLayProps={overLayProps}
              onClose={() => setOverlayOpened(false)}
            />
          ) : (
            <></>
          )}
          {users.map((currentUser) => (
            <StyledCard className="card">
              <StyledFontAwesomeIcon
                className="icon"
                $color="#262626"
                icon={regular("user")}
              />
              <Box className="user-info">
                <H5>
                  {currentUser.firstName} {currentUser.lastName}
                </H5>
                <Body>{currentUser.username}</Body>
                <Body>{currentUser.role}</Body>
                <Box className="btn-wrapper">
                  <Button
                    className={`btn-changepass ${
                      currentUser.username == user.username && "disabled"
                    }`}
                    disabled={currentUser.username != user.username}
                  >
                    Veranderd wachtwoord
                  </Button>
                  <Button
                    className={`btn-remove ${
                      currentUser.username == user.username && "disabled"
                    }`}
                    onClick={() => handleDelete(currentUser.id)}
                  >
                    Verwijder
                  </Button>
                </Box>
              </Box>
            </StyledCard>
          ))}
          <StyledCard
            className="card"
            isAddButton={true}
            onClick={() =>
              handleOpenOverlay(
                "Nieuwe gebruiker",
                <NewUserForm onSave={(data) => onSubmit(data)} />
              )
            }
          >
            <StyledFontAwesomeIcon
              className="icon"
              $color="rgba(38, 38, 38, 0.7)"
              icon={faPlus}
            />
            <Box className="user-info">
              <Typography className="add-account">Nieuw Account</Typography>
            </Box>
          </StyledCard>
        </StyledUsers>
      </BlockComponent>
    </>
  );
}
