import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Button } from "@mui/material";
import { format } from "date-fns";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";
import { Body, H3 } from "../Shared/Typography";

type HeaderProps = {
  activeDate: Date;
  onTodayClick?: () => void;
  onNextMonthClick?: () => void;
  onLastMonthClick?: () => void;
};

export const CalanderHeader = ({
  activeDate,
  onTodayClick,
  onNextMonthClick,
  onLastMonthClick,
}: HeaderProps) => {
  return (
    <Box className="header">
      <Button className="button" onClick={onTodayClick}>
        Vandaag
      </Button>
      <H3>{format(activeDate, "MMMM yyyy")}</H3>
      <Box>
        <StyledFontAwesomeIcon
          icon={faChevronLeft}
          $size="1.2rem"
          onClick={onLastMonthClick}
        />
        <StyledFontAwesomeIcon
          icon={faChevronRight}
          $size="1.2rem"
          onClick={onNextMonthClick}
        />
      </Box>
    </Box>
  );
};
