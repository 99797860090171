import { create } from "zustand";
import { NewsArticle } from "../Models/NewsArticle";
import { NewsArticleInputProps } from "../Models/NewsArticleInputProps";
import {
  createNews,
  deleteNews,
  getAllNews,
} from "../Repositories/NewsRepository";

export interface NewsStore {
  fetchNews: () => Promise<NewsArticle[]>;
  addNews: (newsArticle: NewsArticleInputProps) => Promise<number | undefined>;
  removeNews: (id: number) => Promise<number | undefined>;
}

export const useNews = create<NewsStore>(() => ({
  fetchNews: () => {
    return getAllNews();
  },
  addNews: (newsArticle) => {
    return createNews(newsArticle);
  },
  removeNews: (id) => {
    return deleteNews(id);
  },
}));
