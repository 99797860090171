import { Link } from "react-router-dom";
import BlockComponent from "../Shared/Block/Block";
import { Button } from "@mui/material";

type ErrorProps = {
  title?: string;
  description?: string;
};

function ErrorComponent({ title, description }: ErrorProps) {
  return (
    <BlockComponent
      title={title ? title : "Deze pagina werd niet gevonden"}
      description={description ? description : "Onze excuses voor het ongemak."}
    >
      <Link to={"/"}>
        <Button>Ga naar home</Button>
      </Link>
    </BlockComponent>
  );
}

export default ErrorComponent;
