import { Box, styled } from "@mui/material";
import { theme } from "../../globals/theme";

export const StyledReviews = styled(Box)({
  width: "100%",
  ".wrapper": {
    margin: "auto",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    width: "100%",
    justifyContent: "center",
    gap: "1rem",
    boxSizing: "border-box",
    ".review": {
      width: "20rem",
      minHeight: "12.5vh",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      p: {
        fontSize: ".8rem",
      },
      h4: {
        color: "#afb0b3",
      },
      button: {
        position: "absolute",
        height: "2rem",
        padding: ".5rem",
        top: "0",
        right: "0",
        "&:hover": {
          color: theme.palette.error,
        },
        ">svg": {
          height: "100%",
        },
      },
    },
  },
  form: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "1rem",
    width: "40%",
    h5: {
      width: "fit-content",
      fontSize: ".9rem",
      fontWeight: "100",
    },
    ">div p": {
      fontSize: ".9rem",
      fontWeight: "100",
    },
  },

  "@media (max-width: 1280px)": {
    ".wrapper": {
      paddingInline: "1rem",
      justifyContent: "center",
      gap: "2rem",
      ".review": {
        width: "auto",
        button: {
          position: "relative",
          inset: "0",
          marginBlock: ".5rem",
          ">svg": {
            height: "100%",
          },
        },
      },
    },
    form: {
      width: "100%",
    },
  },
});
