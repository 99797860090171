import { StyledLogin } from "./Login.styled";
import { useProfile } from "../../Hooks/useProfile";
import { Button, TextField } from "@mui/material";
import { User } from "../../Models/User";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Message } from "../Shared/Message/Message";

type LoginProps = {
  onClose: () => void;
};

export const LoginComponent = ({ onClose }: LoginProps) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const { user, fetchUser } = useProfile();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();

  const onSubmit = async (data: User) => {
    setTimeout(() => {
      if (!user) {
        setMessage("Er werd geen account gevonden met deze gegevens.");
        setResponseStatus(404);
        setShowMessage(true);
      }
    }, 1000);
    const status = await fetchUser(data);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Je bent ingelogd!");
      setTimeout(() => {
        onClose();
      }, 1000);
    }
    setShowMessage(true);
  };

  return (
    <StyledLogin>
      <Message
        status={responseStatus}
        showMessage={showMessage}
        onFinish={() => {
          setShowMessage(false);
          setMessage("");
        }}
      >
        {message}
      </Message>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="E-mail"
          fullWidth
          variant="standard"
          {...register("username", {
            required: "Dit veld is verplicht",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email is niet correct",
            },
          })}
          error={!!errors.username}
          helperText={errors?.username?.message}
        />
        <TextField
          label="Wachtwoord"
          type="password"
          fullWidth
          variant="standard"
          {...register("password", { required: "Dit veld is verplicht" })}
          error={!!errors.password}
          helperText={errors?.password?.message}
        />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Log in
        </Button>
      </form>
    </StyledLogin>
  );
};
