import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../globals/theme";

export const StyledGallery = styled(Box)({
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "1rem",
  ".image": {
    width: "15rem",
    height: "15rem",
    boxShadow: "0px 2px 5px 0px rgba(66,66,66,0.75)",
    position: "relative",
    button: {
      position: "absolute",
      height: "2rem",
      padding: ".5rem",
      top: ".5rem",
      right: ".5rem",
      ">svg": {
        height: "100%",
      },
    },
  },
});

export const StyledFileInputForm = styled("form")({
  paddingBlock: "2rem",
  display: "flex",
  gap: "1rem",
  "input[type='file']": {
    cursor: "pointer",
    opacity: "0",
    position: "absolute",
    width: "15rem",
    height: "100%",
  },
  ".fileInput": {
    position: "relative",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    width: "min-content",
    cursor: "pointer",
    height: "3rem",
    ".input-label": {
      color: theme.palette.secondary.light,
      width: "10rem",
      padding: "0",
    },
    ".icon-wrapper": {
      background: theme.palette.primary.main,
      height: "100%",
      width: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});
