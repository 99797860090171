import styled from "@emotion/styled";
import { Box } from "@mui/material";

type PictureProps = {
  $gridArea: string;
};

export const StyledPictureWrapper = styled(Box)({
  ".grid": {
    display: "grid",
    width: "70%",
    height: "25rem",
    margin: "auto",
    gridRowGap: ".5vh",
    gridColumnGap: ".5vh",
    ".image": {
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    ".image-0": {
      gridArea: "1 / 1 / 3 / 4",
    },
    ".image-1": {
      gridArea: "1 / 4 / 3 / 7",
    },
    ".image-2": {
      gridArea: "3 / 1 / 5 / 3",
    },
    ".image-3": {
      gridArea: "3 / 3 / 5 / 5",
    },
    ".image-4": {
      gridArea: "3 / 5 / 5 / 7",
    },
  },
  ".grid-1": {
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    ".image-0": {
      gridArea: "auto",
    },
  },
  ".grid-2": {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "1fr",
    ".image-0, .image-1": {
      gridArea: "auto",
    },
  },
  ".grid-3": {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateRows: "repeat(4, 1fr)",
    ".image-0": {
      gridArea: "1 / 1 / 3 / 4",
    },
    ".image-1": {
      gridArea: "1 / 4 / 3 / 7",
    },
    ".image-2": {
      gridArea: "3 / 1 / 5 / 7",
    },
  },
  ".grid-4": {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    ".image-0, .image-1, .image-2, .image-3": {
      gridArea: "auto",
    },
  },
  ".grid-5": {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateRows: "repeat(4, 1fr)",
  },
  "@media (max-width: 1280px)": {
    ".grid": {
      width: "90%",
      height: "auto",
      minHeight: "30vh",
    },
    ".grid-2": {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "2fr",
      ".image-0, .image-1": {
        gridArea: "auto",
      },
    },
  },
});

export const StyledPictureBox = styled(Box)<PictureProps>(({ $gridArea }) => ({
  gridArea: $gridArea,
}));
