import { Box, Button, Input, Typography } from "@mui/material";
import BlockComponent from "../Shared/Block/Block";
import { StyledFileInputForm, StyledGallery } from "./Gallery.styled";
import Image from "mui-image";
import { useForm } from "react-hook-form";
import { StyledFontAwesomeIcon } from "../Shared/Shared.styled";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { theme } from "../../globals/theme";
import { useGallery } from "../../Hooks/useGallery";
import { useEffect, useState } from "react";
import { GalleryImage } from "../../Models/Image";
import { Message } from "../Shared/Message/Message";
import { adminName } from "../../globals";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useProfile } from "../../Hooks/useProfile";
import { Body } from "../Shared/Typography";

export const GalleryComponent = () => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const [images, setImages] = useState<GalleryImage[]>([]);
  const { register, handleSubmit } = useForm<GalleryImage>();
  const { addImages, fetchGallery, removeImage } = useGallery();
  const { user } = useProfile();

  useEffect(() => {
    updateItems();
    //eslint-disable-next-line
  }, []);

  const updateItems = () => {
    fetchGallery().then((res) => {
      setImages(res.reverse());
    });
  };

  const onSubmit = async (data: GalleryImage) => {
    const status = await addImages(data.file);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Wijzigingen opgeslagen!");
      updateItems();
    }
    setShowMessage(true);
  };

  const handleRemove = async (id: number) => {
    const status = await removeImage(id);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Afbeelding verwijderd");
      updateItems();
    }
    setShowMessage(true);
  };

  return (
    <BlockComponent title={"Galerij"}>
      <Message
        status={responseStatus}
        showMessage={showMessage}
        onFinish={() => {
          setShowMessage(false);
          setMessage("");
        }}
      >
        {message}
      </Message>
      {user && user.role === adminName && (
        <StyledFileInputForm onSubmit={handleSubmit(onSubmit)}>
          <Box className="fileInput">
            <Input
              type="file"
              {...register("file")}
              inputProps={{ multiple: true }}
            />
            <Typography className="input-label">Foto('s) uploaden</Typography>
            <Box className="icon-wrapper">
              <StyledFontAwesomeIcon
                icon={regular("image")}
                $color={theme.palette.primary.contrastText}
              />
            </Box>
          </Box>
          <Button type="submit">Opslaan</Button>
        </StyledFileInputForm>
      )}
      {images.length === 0 && (
        <Body className="noItems-message">
          PlantenKwekerij Bart Blondeel heeft momenteel nog geen foto's.
        </Body>
      )}
      <StyledGallery>
        {images.map((image) => (
          <>
            <Box className="image">
              <Image src={`data:image/png;base64, ${image.file}`} />
              {user && user.role === adminName && (
                <Button onClick={() => handleRemove(image.id!)}>
                  <StyledFontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </Box>
          </>
        ))}
      </StyledGallery>
    </BlockComponent>
  );
};
