import { styled } from "@mui/material";
import { Box } from "@mui/system";

export const StyledOverlay = styled(Box)({
  zIndex: "9999",
  background: "rgba(0, 0, 0, 0.7)",
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  transition: "all 5s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxSizing: "border-box",
  textAlign: "center",
  ".menu": {
    borderRadius: "5px",
    minHeight: "10vh",
    background: "#F7F7F7",
    boxShadow: "1px 2px 9px #262626",
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingBlock: "1rem",
    boxSizing: "border-box",
    ".header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "2rem",
      h3: {
        height: "min-content",
      },
      ".icon": {
        position: "absolute",
        right: "1rem",
        top: "1rem",
      },
    },
  },
  "@media (max-width: 1280px)": {
    ".menu": {
      width: "90%",
    },
  },
});
