import { Typography, TypographyTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { ReactElement } from "react";

export type TypographyProps = Omit<
  DefaultComponentProps<TypographyTypeMap<{}, "span">>,
  "variant"
>;

export function H1(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h1" />;
}

export function H2(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h2" />;
}

export function H3(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h3" />;
}

export function H4(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h4" />;
}

export function H5(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h5" />;
}

export function H6(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="h6" />;
}

export function Body(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="body1" />;
}

export function Body2(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="body2" />;
}

export function Caption(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="caption" />;
}

export function SubTitle(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="subtitle1" />;
}

export function SubTitle2(props: TypographyProps): ReactElement {
  return <Typography {...props} variant="subtitle2" />;
}
