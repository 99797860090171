import { Box, Button } from "@mui/material";
import { addDays, addMonths, subMonths } from "date-fns";
import { useEffect, useState } from "react";
import BlockComponent from "../Shared/Block/Block";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  StyledButtons,
  StyledCalander,
  StyledCalanderContent,
  StyledDayConfiguration,
} from "./Calander.styled";
import { ClosedDateRange } from "../../Models/ClosedDateRange";
import { useProfile } from "../../Hooks/useProfile";
import ErrorComponent from "../ErrorPage/ErrorPage";
import { adminName } from "../../globals";
import { useCalander } from "../../Hooks/useCalander";
import { Message } from "../Shared/Message/Message";
import { CalanderHeader } from "./CalanderHeader";
import { CalanderWeekdays } from "./CalanderWeekdays";
import { CalanderDays } from "./CalanderDays";
import { H3, H4 } from "../Shared/Typography";
import { OpeningDays } from "../../Models/OpeningDays";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { OpeningHour } from "../../Models/OpeningHours";
import { Controller, useForm } from "react-hook-form";

function CalanderComponent() {
  const [closedDateRanges, setClosedDateRanges] = useState<ClosedDateRange[]>(
    []
  );
  const [defaultClosedDays, setDefaultClosedDays] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [activeDate, setActiveDate] = useState(new Date());
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [openingDays, setOpeningDays] = useState<OpeningDays[]>([]);
  const { user } = useProfile();
  const {
    fetchClosedDateRanges,
    fetchDefaultClosedDays,
    saveClosedDateRange,
    removeClosedDateRange,
    saveDefaultClosedDate,
    removeDefaultClosedDate,
    fetchOpeningDays,
    editOpeningHours,
  } = useCalander();

  useEffect(() => {
    updateItems();
    //eslint-disable-next-line
  }, []);

  const updateItems = () => {
    fetchClosedDateRanges().then((res) => {
      setClosedDateRanges(res);
    });
    fetchDefaultClosedDays().then((res) => {
      setDefaultClosedDays(res);
      setIsLoaded(true);
    });
    fetchOpeningDays().then((res) => {
      setOpeningDays(res);
    });
  };

  const getSelectedClosedDateRange = (): ClosedDateRange | undefined => {
    return closedDateRanges.find(
      (x) =>
        x.startDate === startDate?.toDateString() &&
        x.endDate === endDate?.toDateString()
    );
  };
  const getDatesinRange = (): ClosedDateRange | null => {
    let dateArray = [];
    if (!startDate) return null;
    if (endDate && startDate > endDate) return null;
    let currentDate = startDate;
    let stopDate = endDate;

    if (!stopDate) {
      dateArray.push(new Date(currentDate).toDateString());
      stopDate = startDate;
    }
    while (currentDate <= endDate!) {
      dateArray.push(new Date(currentDate).toDateString());
      currentDate = addDays(currentDate, 1);
    }

    return {
      startDate: startDate.toDateString(),
      endDate: stopDate.toDateString(),
      closedDates: dateArray,
    };
  };

  //Endpoints
  const handleSave = async () => {
    const dateRange = getDatesinRange();
    if (dateRange) {
      const status = await saveClosedDateRange(dateRange);
      if (status) setResponseStatus(status);
      if (status === 201) {
        setMessage("Wijzigingen opgeslagen!");
        updateItems();
      }
      setShowMessage(true);
    }
    setStartDate(undefined);
    setEndDate(undefined);
  };
  const handleDelete = async () => {
    const dateRange = getSelectedClosedDateRange();
    setStartDate(undefined);
    setEndDate(undefined);
    if (dateRange) {
      const status = await removeClosedDateRange(dateRange);
      if (status) setResponseStatus(status);

      if (status === 200) {
        setMessage("Wijzigingen opgeslagen!");
        updateItems();
      }
      setShowMessage(true);
    }
  };
  const handleSaveWeekday = async (day: number) => {
    const status = await saveDefaultClosedDate(day);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Wijzigingen opgeslagen!");
      updateItems();
    }
    setShowMessage(true);
  };
  const handleRemoveWeekday = async (day: number) => {
    const status = await removeDefaultClosedDate(day);
    if (status) setResponseStatus(status);
    if (status === 200) {
      setMessage("Wijzigingen opgeslagen!");
      updateItems();
    }
    setShowMessage(true);
  };

  //openingsHours
  const { handleSubmit, control } = useForm<OpeningHour[]>();

  const onSubmit = async (data: OpeningHour[]) => {
    const dataArray = Object.values(data);
    let openingsHours = [];
    let dayIndex = 1;

    for (let day of dataArray) {
      if (day.openingsHour) {
        const openingsHour: OpeningHour = {
          dayIndex: dayIndex,
          openingsHour: dayjs(day.openingsHour).isValid()
            ? dayjs(day.openingsHour).format("HH:mm")
            : day.openingsHour,
          closingHour: dayjs(day.closingHour).isValid()
            ? dayjs(day.closingHour).format("HH:mm")
            : day.closingHour,
        };
        openingsHours.push(openingsHour);
        dayIndex++;
      }
      console.log(openingsHours);
    }

    const status = await editOpeningHours(openingsHours);
    if (status) setResponseStatus(status);
    if (status === 201) {
      setMessage("Wijzigingen opgeslagen!");
      updateItems();
    }
    setShowMessage(true);
  };

  if (!user || (user && user.role !== adminName))
    return (
      <ErrorComponent
        title="Je hebt geen rechten om deze pagina te bekijken"
        description="401 Not authorized"
      />
    );

  return (
    <>
      <BlockComponent title="Kalender">
        <Message
          status={responseStatus}
          showMessage={showMessage}
          onFinish={() => {
            setShowMessage(false);
            setMessage("");
          }}
        >
          {message}
        </Message>
        {isLoaded && (
          <StyledCalanderContent>
            <StyledCalander>
              <CalanderHeader
                activeDate={activeDate}
                onTodayClick={() => setActiveDate(new Date())}
                onNextMonthClick={() => setActiveDate(addMonths(activeDate, 1))}
                onLastMonthClick={() => setActiveDate(subMonths(activeDate, 1))}
              />
              <CalanderWeekdays
                activeDate={activeDate}
                defaultClosedDays={defaultClosedDays}
                saveWeekday={(day) => handleSaveWeekday(day)}
                removeWeekday={(day) => handleRemoveWeekday(day)}
              />
              <CalanderDays
                activeDate={activeDate}
                closedDateRanges={closedDateRanges}
                selectStartDate={(date) => setStartDate(date)}
                selectEndDate={(date) => setEndDate(date)}
                startDate={startDate}
                endDate={endDate}
              />
              <StyledButtons>
                <Button className="btn btn-save" onClick={handleSave}>
                  Gesloten dagen Inplannen
                </Button>
                <Button className="btn btn-remove" onClick={handleDelete}>
                  Verwijder geslecteerde dagen
                </Button>
                <Button
                  className="btn btn-reset"
                  onClick={() => {
                    setStartDate(undefined);
                    setEndDate(undefined);
                  }}
                >
                  Reset
                </Button>
              </StyledButtons>
            </StyledCalander>
            <StyledDayConfiguration>
              <H3>Openingsuren</H3>

              <Box className="day-wrapper">
                <form onSubmit={handleSubmit(onSubmit)}>
                  {openingDays.map((day, index) => (
                    <Box className="day">
                      <H4>{day.day}</H4>
                      <Box className="timepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Controller
                            control={control}
                            name={`${index}.openingsHour`}
                            defaultValue={day.openingsHour}
                            rules={{ required: "Dit veld is verplicht" }}
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                label="Gaat open om"
                                ampm={false}
                                onChange={onChange}
                                value={dayjs(`2023-18-05T${value}`)}
                              />
                            )}
                          />

                          <H4>-</H4>

                          <Controller
                            control={control}
                            name={`${index}.closingHour`}
                            defaultValue={day.closingHour}
                            rules={{ required: "Dit veld is verplicht" }}
                            render={({ field: { onChange, value } }) => (
                              <TimePicker
                                label="Sluit om"
                                ampm={false}
                                onChange={onChange}
                                value={dayjs(`0000-00-00T${value}`)}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                  ))}
                  <Button type="submit">Opslaan</Button>
                </form>
              </Box>
            </StyledDayConfiguration>
          </StyledCalanderContent>
        )}
      </BlockComponent>
    </>
  );
}

export default CalanderComponent;
