import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledLogin = styled(Box)({
  padding: "1rem 3rem",
  width: "30vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ">div p": {
      fontSize: ".9rem",
      fontWeight: "100",
    },
  },
  "@media (max-width: 1280px)": {
    width: "90%",
  },
});
