import { isSameDay, isSameMonth } from "date-fns";
import { ClosedDateRange } from "../Models/ClosedDateRange";

export const format = (dateTime: Date) => {
  const toString = (number: number, padLength: number) => {
    return number.toString().padStart(padLength, "0");
  };

  const formattedDateTime = `${toString(dateTime.getMonth() + 1, 2)}/${toString(
    dateTime.getDate(),
    2
  )}/${toString(dateTime.getFullYear(), 4)} ${toString(
    dateTime.getHours(),
    2
  )}:${toString(dateTime.getMinutes(), 2)}`;

  return formattedDateTime;
};

const isDateInRange = (date: Date, start?: Date, end?: Date): boolean => {
  if (!start || !end) return false;
  return date >= start && date <= end;
};

type CssProps = {
  color: string;
  background: string;
  borderRadius: string;
};

export const getDateCss = (
  closedDateRanges: ClosedDateRange[],
  currentDate: Date,
  startDate: Date,
  endDate: Date
): CssProps => {
  let _color: string = "";
  let _background: string = "";
  let _borderRadius = {
    topLeft: "0",
    topRight: "0",
    bottomLeft: "0",
    bottomRight: "0",
  };

  const getClosedDate = () => {
    let _isClosed = false;
    for (let closedDateRange of closedDateRanges) {
      if (
        closedDateRange.closedDates.includes(
          new Date(currentDate).toDateString()
        )
      ) {
        _isClosed = true;
      }

      if (closedDateRange.startDate === new Date(currentDate).toDateString()) {
        _borderRadius.topLeft = "50px";
        _borderRadius.bottomLeft = "50px";
      }

      if (closedDateRange.endDate === new Date(currentDate).toDateString()) {
        _borderRadius.topRight = "50px";
        _borderRadius.bottomRight = "50px";
      }
    }

    return _isClosed;
  };

  const getTextColor = () => {
    return (
      isSameDay(currentDate, new Date()) ||
      getClosedDate() ||
      isDateInRange(currentDate, startDate, endDate) ||
      isSameDay(currentDate, startDate!)
    );
  };

  if (!isSameMonth(currentDate, new Date())) _color = "#545454";
  if (getTextColor()) _color = "#FFFFFF";

  if (isSameDay(currentDate, new Date())) _background = "#c2c2c2"; //Is currentDay today?
  if (getClosedDate()) _background = "#a83632"; //Is currentDay closed?
  if (
    isDateInRange(currentDate, startDate, endDate) ||
    isSameDay(currentDate, startDate!)
  ) {
    //Is currentDay selected
    _background = "#B6C561";
  }
  if (isDateInRange(currentDate, startDate, endDate) && getClosedDate()) {
    //Is currentDay selected
    _background = "#c7993e";
  }

  if (isSameDay(currentDate, startDate)) {
    //Is currentDay selected && firstDay
    _borderRadius.topLeft = "50px";
    _borderRadius.bottomLeft = "50px";
  }
  if (isSameDay(currentDate, endDate)) {
    //Is currentDay selected && lasyDay
    _borderRadius.topRight = "50px";
    _borderRadius.bottomRight = "50px";
  }
  if (
    (isSameDay(currentDate, startDate) && !endDate) || //Is currentDay selected && endDay is undefined => firstday === lasyDay
    (isSameDay(currentDate, new Date()) &&
      !isDateInRange(currentDate, startDate, endDate) &&
      !getClosedDate()) //Is currentDay today && is not in range of selected && is not a closed date
  ) {
    _borderRadius.topRight = "50px";
    _borderRadius.bottomRight = "50px";
    _borderRadius.topLeft = "50px";
    _borderRadius.bottomLeft = "50px";
  }

  return {
    color: _color,
    background: _background,
    borderRadius: `${_borderRadius.topLeft} ${_borderRadius.topRight} ${_borderRadius.bottomRight} ${_borderRadius.bottomLeft}`,
  };
};

export const getBase64 = (files: File[] | undefined): Promise<string[]> => {
  return new Promise(function (resolve, reject) {
    let newFiles: string[] = [];

    if (!files) {
      resolve([]);
      return;
    }

    const promises: Promise<void>[] = [];
    for (let file of files) {
      const promise = new Promise<void>((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          newFiles.push(reader.result?.toString()!);
          resolve();
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
      promises.push(promise);
    }

    Promise.all(promises)
      .then(() => {
        resolve(newFiles);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
