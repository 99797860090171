import axios, { AxiosResponse } from "axios";
import { NewsArticle } from "../Models/NewsArticle";
import { NewsArticleInputProps } from "../Models/NewsArticleInputProps";
import { serverUrl } from "../globals";

export const getAllNews = async () => {
  const { data } = await axios.get<NewsArticle[]>(`${serverUrl}/news/getall`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });

  return data;
};

export const createNews = async (
  newsArticle: NewsArticleInputProps
): Promise<number> => {
  const formData = new FormData();
  if (newsArticle.files) {
    const fileList = Array.from(newsArticle.files);

    fileList.forEach((file) => {
      formData.append("files", file);
    });
  }

  formData.append("document", JSON.stringify(newsArticle));

  try {
    const response: AxiosResponse = await axios.post<NewsArticle>(
      `${serverUrl}/news/create`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteNews = async (id: number) => {
  try {
    const response: AxiosResponse = await axios.delete<NewsArticle>(
      `${serverUrl}/news/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
