import { Box, styled } from "@mui/material";

export const StyledBlock = styled(Box)({
  width: "100%",
  minHeight: "60vh",
  zIndex: "997",
  background: "#F7F7F7",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  padding: "5vh 15rem",
  boxSizing: "border-box",
  marginTop: "5vh",
  textAlign: "center",
  ".noItems-message": {
    width: "50%",
    textAlign: "center",
  },

  "@media (max-width: 1280px)": {
    padding: "4rem 2rem",
    ".noItems-message": {
      width: "100%",
    },
  },
});
