import { Link, useLocation } from "react-router-dom";
import { NavbarButtonProps } from "../../../Models/Props/NavbarButtonProps";
import { StyledImage } from "../Shared.styled";
import { StyledNavbarButton } from "./Navbar.styled";

export interface ButtonProps {
  buttonProps: NavbarButtonProps;
  onClick?: () => void;
}

function NavBarComponent({ buttonProps, onClick }: ButtonProps) {
  const location = useLocation();
  return (
    <Link
      to={buttonProps.link === "/any" ? location.pathname : buttonProps.link}
    >
      <StyledNavbarButton
        $width={buttonProps.width}
        $height={buttonProps.height}
        $hoverstyle={buttonProps.hoverStyle}
        onClick={onClick}
      >
        {buttonProps.imageSrc && (
          <StyledImage src={require("../../../Images/Main/Logo.png")} />
        )}
        {buttonProps.name && <>{buttonProps.name}</>}
      </StyledNavbarButton>
    </Link>
  );
}

export default NavBarComponent;
