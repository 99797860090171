import { create } from "zustand";
import { ClosedDateRange } from "../Models/ClosedDateRange";
import {
  createClosedDateRange,
  createDefaultClosedDate,
  deleteClosedDateRange,
  deleteDefaultClosedDate,
  getAllClosedDateRanges,
  getCurrentWeekDays,
  getDefaultClosedDays,
  updateOpeningHours,
} from "../Repositories/CalanderRepository";
import { OpeningDays } from "../Models/OpeningDays";
import { OpeningHour } from "../Models/OpeningHours";

export interface CalanderStore {
  closedDateRanges?: ClosedDateRange[];
  fetchClosedDateRanges: () => Promise<ClosedDateRange[]>;
  fetchDefaultClosedDays: () => Promise<number[]>;
  fetchOpeningDays: () => Promise<OpeningDays[]>;
  saveClosedDateRange: (
    closedDateRange: ClosedDateRange
  ) => Promise<number | undefined>;
  removeClosedDateRange: (
    closedDateRange: ClosedDateRange
  ) => Promise<number | undefined>;
  saveDefaultClosedDate: (dayIndex: number) => Promise<number | undefined>;
  removeDefaultClosedDate: (dayIndex: number) => Promise<number | undefined>;
  editOpeningHours: (
    openingHours: OpeningHour[]
  ) => Promise<number | undefined>;
}

export const useCalander = create<CalanderStore>((set) => ({
  fetchClosedDateRanges: () => {
    return getAllClosedDateRanges(set);
  },
  fetchDefaultClosedDays: () => {
    return getDefaultClosedDays(set);
  },
  fetchOpeningDays: () => {
    return getCurrentWeekDays();
  },
  saveClosedDateRange: (closedDateRange: ClosedDateRange) => {
    return createClosedDateRange(closedDateRange);
  },
  removeClosedDateRange: (closedDateRange: ClosedDateRange) => {
    return deleteClosedDateRange(closedDateRange);
  },
  saveDefaultClosedDate: (dayIndex: number) => {
    return createDefaultClosedDate(dayIndex);
  },
  removeDefaultClosedDate: (dayIndex: number) => {
    return deleteDefaultClosedDate(dayIndex);
  },
  editOpeningHours: (openingHours: OpeningHour[]) => {
    return updateOpeningHours(openingHours);
  },
}));
