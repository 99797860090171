import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useProfile } from "../../Hooks/useProfile";
import { adminName } from "../../globals";
import { useCalander } from "../../Hooks/useCalander";
import { OpeningDays } from "../../Models/OpeningDays";
import { Body, H4, H5 } from "../Shared/Typography";
import { StyledOpeningHours } from "./OpeningHours.styled";
import Image from "mui-image";

function OpeningHoursComponent() {
  const [openingDays, setOpeningDays] = useState<OpeningDays[]>([]);
  const { user } = useProfile();
  const { fetchOpeningDays } = useCalander();

  useEffect(() => {
    fetchOpeningDays().then((res) => {
      setOpeningDays(res);
    });
  }, [fetchOpeningDays]);

  return (
    <StyledOpeningHours>
      <Box className="content">
        {openingDays.length === 0 && (
          <Image src={require(`../../Images/Main/loading.gif`)} fit="contain" />
        )}
        {openingDays.map((day) => (
          <Box className="day">
            <H4>{day.day}</H4>
            {day.closed ? (
              <H5 className="day-closed">{day.closedMessage}</H5>
            ) : (
              <H5>
                {day.openingsHour} - {day.closingHour}
              </H5>
            )}
          </Box>
        ))}
      </Box>
      {user && user.role === adminName && (
        <>
          <Body>Data aanpassen is enkel mogelijk op een computer</Body>
          <Link to="/calander">
            <Button className="change-button">Aanpassen</Button>
          </Link>
        </>
      )}
    </StyledOpeningHours>
  );
}

export default OpeningHoursComponent;
