import { Box, Button } from "@mui/material";
import { useState } from "react";
import { OverlayProps } from "../../Models/Props/OverlayProps";
import BlockComponent from "../Shared/Block/Block";
import OverlayComponent from "../Shared/Overlay/Overlay";
import { defaultOverlayProps } from "../Shared/Shared.helper";
import { StyledContact } from "./Contact.styled";
import OpeningHoursComponent from "../OpeningHours/OpeningHours";
import { H4, H6 } from "../Shared/Typography";
import { ContactForm } from "./ContactForm/ContactForm";

function ContactComponent() {
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);
  const [overLayProps, setOverLayProps] =
    useState<OverlayProps>(defaultOverlayProps);

  const handleOpenOverlay = (title: string, component: JSX.Element) => {
    setOverlayOpened(true);

    const updatedValue: OverlayProps = { title, component };
    setOverLayProps((overLayProps) => ({
      ...overLayProps,
      ...updatedValue,
    }));
  };

  return (
    <>
      {overlayOpened ? (
        <OverlayComponent
          isOverlayOpened={overlayOpened}
          overLayProps={overLayProps}
          onClose={() => setOverlayOpened(false)}
        />
      ) : (
        <></>
      )}
      <BlockComponent title="Contact">
        <StyledContact>
          {/*eslint-disable-next-line*/}
          <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=leuvensesteenweg%2069%20tielt-winge+(Plantenkwekerij%20Bart%20Blondeel)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
          <Box className="content">
            <Box className="info-wrapper">
              <H6>Plantenkwekerij Bart Blondeel</H6>
              <H4>Leuvensesteenweg 69a, 3390 Sint-Joris-Winge</H4>
              <H4>Telefoon: 016 63 39 32</H4>
              <H4>Mobiel: 0478 92 70 22</H4>
              <H4 className="email-button">
                E-mail:{" "}
                <H4
                  onClick={() =>
                    handleOpenOverlay("Contacteer ons", <ContactForm />)
                  }
                >
                  Stuur Bart Blondeel een e-mail
                </H4>
              </H4>
              <H4>BTW: 0777 021 171</H4>
            </Box>

            <Box className="button-wrapper">
              <Button
                className="contact-button"
                onClick={() =>
                  handleOpenOverlay("Contacteer ons", <ContactForm />)
                }
              >
                Contacteer ons
              </Button>
              <Button
                onClick={() =>
                  handleOpenOverlay("Openingsuren", <OpeningHoursComponent />)
                }
              >
                Bekijk Openingsuren
              </Button>
            </Box>
          </Box>
        </StyledContact>
      </BlockComponent>
    </>
  );
}

export default ContactComponent;
