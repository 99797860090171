import { Box, styled } from "@mui/material";
import { theme } from "../../../globals/theme";

export const StyledFooter = styled(Box)({
  height: "auto",
  background: "#F7F7F7",
  position: "static",
  bottom: "0",
  ".banner": {
    width: "auto",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
  },
  ".banner-top": {
    background: theme.palette.primary.main,
    height: "10rem",
    ".likebtn": {
      color: "#262626",
      background: "none",
      gap: "1rem",
      fontSize: ".9rem",
      textTransform: "none",
      padding: "3px",
      display: "flex",
      justifyContent: "space-evenly",
      transition: "color .25s",
      "&:hover": {
        color: "#F7F7F7",
      },
    },
  },
  ".banner-bottom": {
    background: theme.palette.background.default,
    textAlign: "center",
    ">*": {
      padding: ".5rem",
    },
    ".text-italic": {
      fontStyle: "italic",
    },
    ".text-small": {
      fontSize: ".5rem",
    },
  },
});
