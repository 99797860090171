import { Box, styled } from "@mui/material";
import { theme } from "../../globals/theme";

export const StyledStart = styled(Box)({
  display: "flex",
  flexDirection: "column",
  ".background": {
    position: "fixed",
    backgroundImage: `url("../../../public/Images/Main/background.png")`,
    width: "100%",
    backgroundPosition: "center",
    zIndex: "-1",
    filter: "brightness(0.8)",
    height: "100%",
  },
  ">svg": {
    width: "3rem",
    height: "3rem",
    position: "absolute",
    top: "90dvh",
    margin: "auto",
    left: "50%",
    transform: "translateX(-50%)",
    display: "none",
    color: theme.palette.primary.contrastText,
  },
  ".header-wrapper": {
    height: "100dvh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    button: {
      width: "20rem",
      marginBlock: "2rem",
      fontSize: ".8rem",
    },
  },
  ".content": {
    width: "100%",
    margin: "auto",
    ".seasonal-wrapper": {
      display: "flex",
      flexDirection: "column",
      ".seasonal-card": {
        paddingBlock: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ".divider": {
          width: "5vw",
          background: "#262626",
          height: "2px",
          marginBlock: "1vh",
        },
      },
    },
  },

  "@media (max-width: 1280px)": {
    svg: {
      display: "initial",
    },
    ".header-wrapper": {
      button: {
        width: "auto",
      },
    },
    ".content .seasonal-wrapper .seasonal-card .divider": {
      width: "5rem",
    },
  },
});
