import { create } from "zustand";
import { User } from "../Models/User";
import { createUser, deleteUser, getAllUsers, getUser, updateUser } from "../Repositories/UserRepository";
import { InputValues } from "../Models/InputValues";

export interface UserStore {
  user?: User;
  fetchUser: (inputData: InputValues) => Promise<number | undefined>;
  fetchAllUsers: () => Promise<User[]>;
  saveUser: (user: User) => Promise<number | undefined>;
  removeUser: (id: number) => Promise<number | undefined>;
  changeUser: (user: User) => Promise<number | undefined>;
  logout: () => void;
}

export const useProfile = create<UserStore>((set) => ({
  fetchUser: (inputData) => {
    return getUser(set, inputData);
  },
  fetchAllUsers: () => {
    return getAllUsers();
  },
  saveUser: (user: User) => {
    return createUser(user);
  },
  removeUser: (id: number) => {
    return deleteUser(id);
  },
  changeUser: (user: User) => {
    return updateUser();
  },
  logout: () => {
    set((state) => ({ ...state, user: undefined }));
  },
}));
