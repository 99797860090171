import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { theme } from "../../globals/theme";

export const StyledOpeningHours = styled(Box)({
  width: "40vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBlock: "2rem",
  p: {
    display: "none",
    fontSize: ".8rem",
  },
  ".content": {
    margin: "auto",
    paddingBottom: "1rem",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    marginTop: "2vh",
    fontFamily: "'Raleway', sans-serif",
    gap: ".5rem",
    boxSizing: "border-box",
    ".day": {
      display: "flex",
      justifyContent: "space-between",
      h4: {
        textAlign: "start",
      },
      ">h5": {
        color: theme.palette.primary.main,
        width: "100%",
        textAlign: "end",
        filter: "brightness(90%)",
      },
      ".day-closed": {
        color: theme.palette.error.main,
      },
    },
    ".mui-image-wrapper": {
      height: "5rem !important",
      img: {
        objectFit: "contain",
      },
    },
  },
  ".change-button": {
    width: "20vw",
  },
  "@media (max-width: 1280px)": {
    width: "90%",
    ".content": {
      width: "100%",
      paddingInline: "2rem",
    },
    p: {
      display: "block",
    },
    ".change-button": {
      visibility: "hidden",
    },
  },
});
