import SeasonalCardComponent from "./SeasonalCard";
import { StyledStart } from "./Start.styled";
import { Box } from "@mui/material";
import { seasonalCards } from "../../globals/constants";

function SeasonalWrapperComponent() {
  return (
    <StyledStart>
      <Box className="seasonal-wrapper">
        {seasonalCards.map((card) => (
          <SeasonalCardComponent
            title={card.title}
            description={card.description}
            key={card.title}
          />
        ))}
      </Box>
    </StyledStart>
  );
}

export default SeasonalWrapperComponent;
