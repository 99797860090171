import { StyledStart } from "./Start.styled";
import { Body2, H6 } from "../Shared/Typography";
import { Box } from "@mui/material";

type CardProps = {
  title: string;
  description: string;
};

function SeasonalCardComponent({ title, description }: CardProps) {
  return (
    <StyledStart>
      <Box className="seasonal-card">
        <H6>{title}</H6>
        <Body2>{description}</Body2>
        <Box className="divider" />
      </Box>
    </StyledStart>
  );
}

export default SeasonalCardComponent;
