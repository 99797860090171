import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CalanderComponent from "./Components/Calander/Calander";
import ContactComponent from "./Components/Contact/Contact";
import ErrorPageComponent from "./Components/ErrorPage/ErrorPage";
import NewsComponent from "./Components/News/News";
import ReviewsComponent from "./Components/Reviews/Reviews";
import FooterComponent from "./Components/Shared/Footer/Footer";
import NavBarComponent from "./Components/Shared/Navbar/Navbar";
import StartComponent from "./Components/Start/Start";
import "./index.css";
import { UsersComponent } from "./Components/Users/Users";
import { ThemeProvider } from "@mui/material";
import { theme } from "./globals/theme";
import { GalleryComponent } from "./Components/Gallery/Gallery";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <NavBarComponent />

        <Routes>
          <Route path="/" element={<StartComponent />} />
          <Route path="/news" element={<NewsComponent />} />
          <Route path="/reviews" element={<ReviewsComponent />} />
          <Route path="/contact" element={<ContactComponent />} />
          <Route path="/calander" element={<CalanderComponent />} />
          <Route path="/gallery" element={<GalleryComponent />} />
          <Route path="/users" element={<UsersComponent />} />
          <Route path="*" element={<ErrorPageComponent />} />
        </Routes>

        <FooterComponent />
      </Router>
    </ThemeProvider>
  </React.StrictMode>
);
