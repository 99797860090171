import { create } from "zustand";
import { Review } from "../Models/Review";
import { createReview, deleteReview, getAllReviews } from "../Repositories/ReviewsRepository";

export interface ReviewsStore {
  reviews?: Review[];
  fetchReviews: () => Promise<Review[]>;
  saveReview: (review: Review) => Promise<number | undefined>;
  removeReview: (id: number) => Promise<number | undefined>;
}

export const useReviews = create<ReviewsStore>((set) => ({
  fetchReviews: () => {
    return getAllReviews(set);
  },
  saveReview: (review: Review) => {
    return createReview(review);
  },
  removeReview: (id: number) => {
    return deleteReview(id);
  },
}));
