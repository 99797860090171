import { Box } from "@mui/material";
import BlockComponent from "../Shared/Block/Block";
import { StyledImage, StyledLink } from "../Shared/Shared.styled";
import SeasonalWrapperComponent from "./SeasonalWrapper";
import { StyledStart } from "./Start.styled";
import { Body, Caption, H1, H2 } from "../Shared/Typography";
import { useState } from "react";
import { OverlayProps } from "../../Models/Props/OverlayProps";
import OverlayComponent from "../Shared/Overlay/Overlay";
import { defaultOverlayProps } from "../Shared/Shared.helper";
import OpeningHoursComponent from "../OpeningHours/OpeningHours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function StartComponent() {
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);
  const [overLayProps, setOverLayProps] =
    useState<OverlayProps>(defaultOverlayProps);

  const handleOpenOverlay = (title: string, component: JSX.Element) => {
    setOverlayOpened(true);

    const updatedValue: OverlayProps = { title, component };
    setOverLayProps((overLayProps) => ({
      ...overLayProps,
      ...updatedValue,
    }));
  };

  return (
    <>
      {overlayOpened ? (
        <OverlayComponent
          isOverlayOpened={overlayOpened}
          overLayProps={overLayProps}
          onClose={() => setOverlayOpened(false)}
        />
      ) : (
        <></>
      )}
      <StyledStart>
        <Box className="background">
          <StyledImage
            src={require("../../Images/Main/background.png")}
            duration={500}
          />
        </Box>
        <FontAwesomeIcon icon={faChevronDown} />
        <Box className="header-wrapper">
          <H1>Plantenkwekerij</H1>
          <H2>Tielt-Winge</H2>
        </Box>
        <BlockComponent
          title={"Plantenkwekerij Bart Blondeel"}
          description={
            "De specialist in bloemen, planten, potgrond en decoratie te Tielt-Winge sinds 1989"
          }
        >
          <Box className="content">
            <Body>
              Plantenkwekerij Bart Blondeel is ontstaan in 1989 door het
              verkopen op de markten in de buurt van Leuven. Zo is Bart Blondeel
              na 9 jaar verkopen op de markt begonnen met het bouwen van een
              serre. In 1998 starte de werken in Tielt-Winge (Sint-Joris-Winge)
              en dit is nog steeds de locatie waar we tot de dag van vandaag
              zelf onze planten kweken. In onze serre te Tielt-Winge kan u
              altijd terecht om te kiezen tussen een zeer ruim aanbod van
              bloemen, planten en decoratie.
            </Body>
            <Body>
              Onze plantenkwekerij stond ook tot 2014 op de vrijdagmarkt in
              Leuven. Nu staan we elke zondag op de markt in Heverlee. U kan
              gerust eens langskomen in onze serre of ons een bezoekje brengen
              op de markt. Bekijk alvast{" "}
              <StyledLink
                to={"/"}
                onClick={() =>
                  handleOpenOverlay("Openingsuren", <OpeningHoursComponent />)
                }
              >
                Hier
              </StyledLink>{" "}
              onze openingsuren. Tot snel!
            </Body>

            <Caption>Wat verkoopt plantenkwekerij Bart Blondeel?</Caption>
            <SeasonalWrapperComponent />
            <Body>
              Tip: Wij vullen ook uw bloembakken. Naast de seizoensgebonden
              planten kan u bij ons ook altijd terecht voor uw decoratie,
              bloempotten en potgrond alsook advies voor uw planten. Het aanbod
              verschilt regelmatig dus kom gerust een kijkje te nemen in onze
              plantenkwekerij te Tielt-Winge!
            </Body>
          </Box>
        </BlockComponent>
      </StyledStart>
    </>
  );
}

export default StartComponent;
