import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Box, Fade, Slide } from "@mui/material";
import { OverlayProps } from "../../../Models/Props/OverlayProps";
import { StyledFontAwesomeIcon } from "../Shared.styled";
import { StyledOverlay } from "./Overlay.styled";
import { H3 } from "../Typography";

type OverlayMenuProps = {
  isOverlayOpened: boolean;
  onClose: () => void;
  overLayProps: OverlayProps;
};

function OverlayComponent({
  isOverlayOpened,
  onClose,
  overLayProps,
}: OverlayMenuProps) {
  return (
    <Fade in={isOverlayOpened}>
      <StyledOverlay>
        <Slide direction="down" in={isOverlayOpened}>
          <Box className="menu">
            <Box className="header">
              <H3>{overLayProps.title}</H3>
              <StyledFontAwesomeIcon
                className="icon"
                icon={faClose}
                $color="#262626"
                onClick={onClose}
              />
            </Box>
            {overLayProps.component}
          </Box>
        </Slide>
      </StyledOverlay>
    </Fade>
  );
}

export default OverlayComponent;
