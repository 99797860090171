import axios, { AxiosResponse } from "axios";
import { serverUrl } from "../globals";
import { GalleryImage } from "../Models/Image";
import { MulterFile } from "../Models/MulterFile";

export const getGallery = async () => {
  const { data } = await axios.get<GalleryImage[]>(
    `${serverUrl}/gallery/getall`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return data;
};

export const createImage = async (images: FileList): Promise<number> => {
  const formData = new FormData();
  const fileList = Array.from(images);

  fileList.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response: AxiosResponse = await axios.post<MulterFile>(
      `${serverUrl}/gallery/create`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteImage = async (id: number) => {
  try {
    const response: AxiosResponse = await axios.delete<GalleryImage>(
      `${serverUrl}/gallery/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
