import axios, { AxiosResponse } from "axios";
import { Review } from "../Models/Review";
import { ReviewsStore } from "../Hooks/useReviews";
import { Set } from "../Models/Set";
import { serverUrl } from "../globals";

export const getAllReviews = async (set: Set<ReviewsStore>) => {
  const { data } = await axios.get<Review[]>(
    `${serverUrl}/reviews/getall`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  set((state) => ({ ...state, reviews: data }));
  return data;
};

export const createReview = async (review: Review): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.post<Review>(
      `${serverUrl}/reviews/create`,
      review,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteReview = async (
  id: number
) => {
  try {
    const response: AxiosResponse = await axios.delete<Review>(
      `${serverUrl}/reviews/delete/${id}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};